exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/App.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-blog-katie-index-tsx": () => import("./../../../src/pages/blog/Katie/index.tsx" /* webpackChunkName: "component---src-pages-blog-katie-index-tsx" */),
  "component---src-pages-chatbot-index-tsx": () => import("./../../../src/pages/chatbot/index.tsx" /* webpackChunkName: "component---src-pages-chatbot-index-tsx" */),
  "component---src-pages-csc-azure-app-gateway-index-tsx": () => import("./../../../src/pages/csc/azure/app-gateway/index.tsx" /* webpackChunkName: "component---src-pages-csc-azure-app-gateway-index-tsx" */),
  "component---src-pages-csc-azure-asgnsg-index-tsx": () => import("./../../../src/pages/csc/azure/asgnsg/index.tsx" /* webpackChunkName: "component---src-pages-csc-azure-asgnsg-index-tsx" */),
  "component---src-pages-csc-azure-azure-buildsheet-index-tsx": () => import("./../../../src/pages/csc/azure/azure-buildsheet/index.tsx" /* webpackChunkName: "component---src-pages-csc-azure-azure-buildsheet-index-tsx" */),
  "component---src-pages-csc-azure-ddve-create-index-tsx": () => import("./../../../src/pages/csc/azure/ddve-create/index.tsx" /* webpackChunkName: "component---src-pages-csc-azure-ddve-create-index-tsx" */),
  "component---src-pages-csc-azure-index-tsx": () => import("./../../../src/pages/csc/azure/index.tsx" /* webpackChunkName: "component---src-pages-csc-azure-index-tsx" */),
  "component---src-pages-csc-azure-lb-create-index-tsx": () => import("./../../../src/pages/csc/azure/lb-create/index.tsx" /* webpackChunkName: "component---src-pages-csc-azure-lb-create-index-tsx" */),
  "component---src-pages-csc-azure-policy-exemption-index-tsx": () => import("./../../../src/pages/csc/azure/policy-exemption/index.tsx" /* webpackChunkName: "component---src-pages-csc-azure-policy-exemption-index-tsx" */),
  "component---src-pages-csc-azure-rg-create-index-tsx": () => import("./../../../src/pages/csc/azure/rg-create/index.tsx" /* webpackChunkName: "component---src-pages-csc-azure-rg-create-index-tsx" */),
  "component---src-pages-csc-azure-rpa-index-tsx": () => import("./../../../src/pages/csc/azure/rpa/index.tsx" /* webpackChunkName: "component---src-pages-csc-azure-rpa-index-tsx" */),
  "component---src-pages-csc-azure-st-create-index-tsx": () => import("./../../../src/pages/csc/azure/st-create/index.tsx" /* webpackChunkName: "component---src-pages-csc-azure-st-create-index-tsx" */),
  "component---src-pages-csc-azure-submitted-tsx": () => import("./../../../src/pages/csc/azure/submitted.tsx" /* webpackChunkName: "component---src-pages-csc-azure-submitted-tsx" */),
  "component---src-pages-csc-azure-unavailable-tsx": () => import("./../../../src/pages/csc/azure/unavailable.tsx" /* webpackChunkName: "component---src-pages-csc-azure-unavailable-tsx" */),
  "component---src-pages-csc-azure-vm-create-index-tsx": () => import("./../../../src/pages/csc/azure/vm-create/index.tsx" /* webpackChunkName: "component---src-pages-csc-azure-vm-create-index-tsx" */),
  "component---src-pages-csc-azure-vmasgnsg-index-tsx": () => import("./../../../src/pages/csc/azure/vmasgnsg/index.tsx" /* webpackChunkName: "component---src-pages-csc-azure-vmasgnsg-index-tsx" */),
  "component---src-pages-csc-gcp-alloydb-index-tsx": () => import("./../../../src/pages/csc/gcp/alloydb/index.tsx" /* webpackChunkName: "component---src-pages-csc-gcp-alloydb-index-tsx" */),
  "component---src-pages-csc-gcp-cloud-postgresql-index-tsx": () => import("./../../../src/pages/csc/gcp/cloud-postgresql/index.tsx" /* webpackChunkName: "component---src-pages-csc-gcp-cloud-postgresql-index-tsx" */),
  "component---src-pages-csc-gcp-elastic-index-tsx": () => import("./../../../src/pages/csc/gcp/elastic/index.tsx" /* webpackChunkName: "component---src-pages-csc-gcp-elastic-index-tsx" */),
  "component---src-pages-csc-gcp-gcp-buildsheet-index-tsx": () => import("./../../../src/pages/csc/gcp/gcp-buildsheet/index.tsx" /* webpackChunkName: "component---src-pages-csc-gcp-gcp-buildsheet-index-tsx" */),
  "component---src-pages-csc-gcp-index-tsx": () => import("./../../../src/pages/csc/gcp/index.tsx" /* webpackChunkName: "component---src-pages-csc-gcp-index-tsx" */),
  "component---src-pages-csc-gcp-vm-create-index-tsx": () => import("./../../../src/pages/csc/gcp/vm-create/index.tsx" /* webpackChunkName: "component---src-pages-csc-gcp-vm-create-index-tsx" */),
  "component---src-pages-csc-index-tsx": () => import("./../../../src/pages/csc/index.tsx" /* webpackChunkName: "component---src-pages-csc-index-tsx" */),
  "component---src-pages-csc-openai-index-tsx": () => import("./../../../src/pages/csc/openai/index.tsx" /* webpackChunkName: "component---src-pages-csc-openai-index-tsx" */),
  "component---src-pages-csc-parkmyvm-index-tsx": () => import("./../../../src/pages/csc/parkmyvm/index.tsx" /* webpackChunkName: "component---src-pages-csc-parkmyvm-index-tsx" */),
  "component---src-pages-csc-sharepointcsc-index-tsx": () => import("./../../../src/pages/csc/sharepointcsc/index.tsx" /* webpackChunkName: "component---src-pages-csc-sharepointcsc-index-tsx" */),
  "component---src-pages-dashboards-admindashboard-tsx": () => import("./../../../src/pages/dashboards/admindashboard.tsx" /* webpackChunkName: "component---src-pages-dashboards-admindashboard-tsx" */),
  "component---src-pages-dashboards-hapdashboard-tsx": () => import("./../../../src/pages/dashboards/hapdashboard.tsx" /* webpackChunkName: "component---src-pages-dashboards-hapdashboard-tsx" */),
  "component---src-pages-dashboards-index-tsx": () => import("./../../../src/pages/dashboards/index.tsx" /* webpackChunkName: "component---src-pages-dashboards-index-tsx" */),
  "component---src-pages-dashboards-mydashboard-tsx": () => import("./../../../src/pages/dashboards/mydashboard.tsx" /* webpackChunkName: "component---src-pages-dashboards-mydashboard-tsx" */),
  "component---src-pages-dashboards-plcyexempdashboard-tsx": () => import("./../../../src/pages/dashboards/plcyexempdashboard.tsx" /* webpackChunkName: "component---src-pages-dashboards-plcyexempdashboard-tsx" */),
  "component---src-pages-dashboards-subprojaudit-tsx": () => import("./../../../src/pages/dashboards/subprojaudit.tsx" /* webpackChunkName: "component---src-pages-dashboards-subprojaudit-tsx" */),
  "component---src-pages-dashboards-subsandaccts-tsx": () => import("./../../../src/pages/dashboards/subsandaccts.tsx" /* webpackChunkName: "component---src-pages-dashboards-subsandaccts-tsx" */),
  "component---src-pages-documents-index-tsx": () => import("./../../../src/pages/documents/index.tsx" /* webpackChunkName: "component---src-pages-documents-index-tsx" */),
  "component---src-pages-expenv-current-index-tsx": () => import("./../../../src/pages/expenv/current/index.tsx" /* webpackChunkName: "component---src-pages-expenv-current-index-tsx" */),
  "component---src-pages-expenv-index-tsx": () => import("./../../../src/pages/expenv/index.tsx" /* webpackChunkName: "component---src-pages-expenv-index-tsx" */),
  "component---src-pages-expenv-persona-index-tsx": () => import("./../../../src/pages/expenv/persona/index.tsx" /* webpackChunkName: "component---src-pages-expenv-persona-index-tsx" */),
  "component---src-pages-faq-index-tsx": () => import("./../../../src/pages/faq/index.tsx" /* webpackChunkName: "component---src-pages-faq-index-tsx" */),
  "component---src-pages-feedback-index-tsx": () => import("./../../../src/pages/feedback/index.tsx" /* webpackChunkName: "component---src-pages-feedback-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learn-index-tsx": () => import("./../../../src/pages/learn/index.tsx" /* webpackChunkName: "component---src-pages-learn-index-tsx" */),
  "component---src-pages-learn-persist-index-tsx": () => import("./../../../src/pages/learn/persist/index.tsx" /* webpackChunkName: "component---src-pages-learn-persist-index-tsx" */),
  "component---src-pages-learn-prepare-index-tsx": () => import("./../../../src/pages/learn/prepare/index.tsx" /* webpackChunkName: "component---src-pages-learn-prepare-index-tsx" */),
  "component---src-pages-learn-pursue-index-tsx": () => import("./../../../src/pages/learn/pursue/index.tsx" /* webpackChunkName: "component---src-pages-learn-pursue-index-tsx" */),
  "component---src-pages-mockup-index-tsx": () => import("./../../../src/pages/mockup/index.tsx" /* webpackChunkName: "component---src-pages-mockup-index-tsx" */),
  "component---src-pages-newuseraccess-index-tsx": () => import("./../../../src/pages/newuseraccess/index.tsx" /* webpackChunkName: "component---src-pages-newuseraccess-index-tsx" */),
  "component---src-pages-pilot-index-tsx": () => import("./../../../src/pages/pilot/index.tsx" /* webpackChunkName: "component---src-pages-pilot-index-tsx" */),
  "component---src-pages-qrcode-index-tsx": () => import("./../../../src/pages/qrcode/index.tsx" /* webpackChunkName: "component---src-pages-qrcode-index-tsx" */),
  "component---src-pages-reports-index-tsx": () => import("./../../../src/pages/reports/index.tsx" /* webpackChunkName: "component---src-pages-reports-index-tsx" */),
  "component---src-pages-response-page-tsx": () => import("./../../../src/pages/ResponsePage.tsx" /* webpackChunkName: "component---src-pages-response-page-tsx" */),
  "component---src-pages-search-index-tsx": () => import("./../../../src/pages/search/index.tsx" /* webpackChunkName: "component---src-pages-search-index-tsx" */),
  "component---src-pages-stream-index-tsx": () => import("./../../../src/pages/stream/index.tsx" /* webpackChunkName: "component---src-pages-stream-index-tsx" */)
}

